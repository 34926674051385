/* client/src/App.css */
html,
body {
  margin: 0;
  padding: 0;
  background-color: #eeeeee;
  height: 100%; /* Ensure body takes full height */
  font-family: "Oswald", Arial, sans-serif; /* Apply Oswald font */
  background-image: url("./assets/images/background2.png"); /* Add this line */
  background-size: cover; /* Ensure the image covers the entire background */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent repeating of the image */
}

.App {
  font-family: "Oswald", Arial, sans-serif; /* Apply Oswald font */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  min-height: 100vh; /* Ensure it takes at least the full viewport height */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  overflow: hidden; /* Prevent scrolling */
}

.header {
  margin-bottom: 20px;
}

.session-section {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.create-session,
.join-session {
  width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
}

.create-session h2,
.join-session h2 {
  margin-bottom: 10px;
  font-weight: lighter;
}

.create-session button,
.join-session button {
  margin-top: 10px;
}

.table {
  margin-top: 20px;
  text-align: center;
}

.cards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.estimates {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.estimate-card {
  width: 125px;
  height: 175px;
  background-color: white;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative; /* Important for layering */
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.estimate-inner-card {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3; /* Ensure text is on top */
}

.estimate-inner-card p {
  position: relative;
  z-index: 3; /* Ensure text is on top */
  color: white;
  font-size: 44px;
  font-weight: bold;
}

.estimate-inner-card .card-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 87%; /* Adjust size as needed */
  height: 89%; /* Adjust size as needed */
  object-fit: cover; /* Ensure image maintains its aspect ratio */
  border-radius: 0; /* Match the card’s border radius */
  transform: translate(-50%, -50%); /* Center the image */
  z-index: 1; /* Keep the image behind other content */
}

/* .estimate-innermost-card {
  width: 97px;
  height: 157px;
  background-color: #007bff;
  border-radius: 8.5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 44px;
  font-weight: bold;
  text-align: center;
} */

.average-closest-container {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  gap: 10px;
}

.share-button {
  font-family: "Oswald", Arial, sans-serif; /* Apply Oswald font */
  background-color: white;
  color: rgb(83, 83, 83);
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
}

.share-button:hover {
  background-color: #007bff; /* Green background */
  color: white;
  border: none;
}

/* Ensure the icon is vertically centered within the button */
.share-button .fa-share-alt {
  vertical-align: middle;
  margin-right: 5px;
}

/* client/src/App.css */
@keyframes flip {
  0% {
    transform: perspective(400px) rotateY(0);
  }
  50% {
    transform: perspective(400px) rotateY(90deg);
  }
  100% {
    transform: perspective(400px) rotateY(0);
  }
}

.average-card,
.closest-card {
  width: 60px;
  height: 90px;
  background-color: #3596ff;
  color: rgb(255, 255, 255);
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(128, 128, 128, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: flip 0.6s ease;
  transform-style: preserve-3d;
}

.small-text {
  font-size: 12px;
}

.large-number {
  font-size: 24px;
  font-weight: bold;
  margin-top: 5px;
}

.card-deck {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 30px;
}

.card-deck .card-image {
  opacity: 0.8; /* 50% transparent */
}

.card {
  width: 80px;
  height: 120px;
  background-color: #ffffff;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(83, 83, 83);
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  position: relative; /* Important for layering */
}
.card-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%; /* Make sure the image covers the entire card */
  height: 100%;
  object-fit: cover; /* Ensures the image maintains its aspect ratio */
  border-radius: 10px;
  z-index: 1; /* Keep the image behind the text */
  transform: translate(-50%, -50%); /* Centers the image */
}
.card-text {
  position: relative;
  z-index: 2; /* Ensures the text is above the image */
}

.card:hover {
  transform: translateY(-5px);
}

.card.selected {
  background-color: #007bff;
  color: rgb(255, 255, 255);
}

.session-details {
  position: absolute;
  padding: 20px;
  top: 250px;
  right: 20px;
  border-radius: 10px;
  background-color: transparent;
  color: rgb(255, 255, 255);
  padding: 12px 24px;
  z-index: 100;
}

.session-info {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.session-info h2 {
  margin: 0;
  font-size: 18px;
}

.user-name {
  font-size: 14px;
  margin-top: 5px;
}

.user-id-card {
  width: 150px;
  height: 200px;
  background-size: cover;
  background-position: center;
  background-color: white;
  border-radius: 10px;
  margin: 20px auto;
  justify-content: center;
  align-items: flex;
  color: #333;
  font-size: 24px;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: rotate(5deg); /* Slight tilt to the right */
  position: relative;
  position: absolute;
  top: 10px;
  right: 40px;
}

.user-id-text {
  font-size: 24px;
  font-weight: bold;
  color: white;
  margin-bottom: 10px;
}

.user-icon {
  font-size: 80px;
  color: white;
}

.body {
  margin: 0;
  font-family: "Oswald", Arial, sans-serif; /* Apply Oswald font */
  background-color: #f0f0f0;
}

.session-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  padding: 20px;
  box-sizing: border-box;
}

.session-container img {
  max-width: 100%;
  height: auto;
}

.session-container-input {
  z-index: 2; /* Ensures the button is above the image and text */
  font-family: "Oswald", Arial, sans-serif; /* Apply Oswald font */
  width: 100%;
  max-width: 250px; /* Adjust as needed to fit your card design */
  padding: 10px;
  margin: 10px 0;
  border: 0px solid #ffffff;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.logo-container {
  position: relative;
  display: inline-block;
}

.logo {
  width: 450px;
  margin-bottom: 80px;
  transition: transform 0.3s ease;
  cursor: pointer; /* Add this line */
}

.logo:hover {
  transform: scale(1.015);
}

.logo-tooltip {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the logo */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap; /* Prevents text wrapping */
}

.logo-container:hover .logo-tooltip {
  visibility: visible;
  opacity: 1;
}

/* General input styling */
.input {
  z-index: 3; /* Ensures the button is above the image and text */
  font-family: "Oswald", Arial, sans-serif; /* Apply Oswald font */
  width: 100%;
  max-width: 250px; /* Adjust as needed to fit your card design */
  padding: 10px;
  margin: 10px 0;
  border: 0px solid #ffffff;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

/* Input hover state */
.input:hover {
  box-shadow: 0 0 10px rgba(189, 189, 189, 0.7);
}

/* Input focus state */
.input:focus {
  box-shadow: 0 0 15px rgb(182, 182, 182);
  outline: none;
}
.input::placeholder {
  color: #c2c2c2; /* Blue color for the placeholder text */
  opacity: 1; /* Ensures the color is not translucent */
}

/* This is for better browser compatibility */
.input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c2c2c2;
}

.input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #c2c2c2;
}

.session-card-container {
  position: relative;
  height: 300px; /* Adjust as needed */
  width: 600px; /* Adjust as needed */
}

.session-card {
  width: 250px;
  height: 350px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  transition: transform 0.5s ease-in-out;
  cursor: pointer;

  /* Centering content inside the card */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.session-card .card-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 87%; /* Adjust size as needed */
  height: 89%; /* Adjust size as needed */
  object-fit: cover; /* Ensure image maintains its aspect ratio */
  border-radius: 0; /* Match the card’s border radius */
  transform: translate(-50%, -50%); /* Center the image */
  z-index: 1; /* Keep the image behind other content */
}

.session-card h2 {
  margin-top: 0;
  font-size: 1.5em;
  color: #ffffff;
  /* font-weight: lighter; */
}

.session-card:nth-child(1) {
  z-index: 2;
}

.session-card:nth-child(2) {
  z-index: 1;
}

@keyframes dealLeft {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-150%, -50%) rotate(-10deg); /* Closer to the center */
  }
}

@keyframes dealRight {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(50%, -50%) rotate(10deg); /* Closer to the center */
  }
}

.deal-left {
  animation: dealLeft 0.5s forwards;
}

.deal-right {
  animation: dealRight 0.5s forwards;
}

.session-card:hover {
  transform: translate(-50%, -50%) rotate(0deg) scale(1.05);
}

.session-card .input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  text-align: center; /* Ensures text is centered inside the container */
}

.session-title {
  color: #007bff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-top: 5px;
  margin-bottom: -10px;
  width: 100%; /* Ensures the title takes up full width */
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  font-family: "Oswald", Arial, sans-serif; /* Apply Oswald font */
  z-index: 3; /* Ensures the button is above the image and text */
  padding: 12px 24px;
  background-color: white;
  color: #007bff;
  border: none;
  font-weight: bold;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s ease-in-out;
  margin: 20px;
}

.button:hover {
  background-color: #0056b3;
  transform: translateY(-3px);
}

.user-names {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.user-names p {
  color: #3596ff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  width: 150px;
  margin-top: 5px;
  margin-bottom: -10px;
}

.session-info {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #3596ff;
  padding: 10px;
  border-radius: 5px;
  text-align: center; /* Center-align text */
  align-items: center;
  justify-content: center;
}

.spectate-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px; /* Ensures it is right below the session-info */
  margin-left: 10px; /* Align it with the session-info content */
  width: 100%;
}

.spectate-toggle h2 {
  font-size: 14px; /* Adjust size if needed */
  color: rgb(83, 83, 83); /* Ensure the text color is blue */
  margin-right: 10px;
}

.spectate-toggle .switch {
  flex-shrink: 0; /* Prevents shrinking when text changes */
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(83, 83, 83);
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #007bff;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

.spectate-toggle span {
  font-size: 14px;
  color: white;
}
